/*font families*/
@font-face {font-family: 'Neon';src: url('https://res.cloudinary.com/arhamsoft-ltd/raw/upload/v1658742665/hex-nft/assets/Fonts/Neon-Regular_bo547o.woff2') format('woff2'),url('https://res.cloudinary.com/arhamsoft-ltd/raw/upload/v1658742665/hex-nft/assets/Fonts/Neon-Regular_clcuma.woff') format('woff');  font-weight: normal; font-style: normal;font-display: swap;}
@font-face {font-family: 'Neon';src: url('https://res.cloudinary.com/arhamsoft-ltd/raw/upload/v1658742664/hex-nft/assets/Fonts/Neon-Medium_zstgmg.woff2') format('woff2'),url('https://res.cloudinary.com/arhamsoft-ltd/raw/upload/v1658742665/hex-nft/assets/Fonts/Neon-Medium_arvbif.woff') format('woff');font-weight: 500;font-style: normal;font-display: swap;}
@font-face {font-family: 'Neon';src: url('https://res.cloudinary.com/arhamsoft-ltd/raw/upload/v1658742666/hex-nft/assets/Fonts/Neon-Black_qlxlrh.woff2') format('woff2'),url('https://res.cloudinary.com/arhamsoft-ltd/raw/upload/v1658742665/hex-nft/assets/Fonts/Neon-Black_njvgxj.woff') format('woff');font-weight: 900;font-style: normal;font-display: swap;}
@font-face {font-family: 'Montserrat';src: url('https://res.cloudinary.com/arhamsoft-ltd/raw/upload/v1658742665/hex-nft/assets/Fonts/Montserrat-Regular_chjelz.woff2') format('woff2'),url('https://res.cloudinary.com/arhamsoft-ltd/raw/upload/v1658742665/hex-nft/assets/Fonts/Montserrat-Regular_b5ajdl.woff') format('woff');font-weight: normal;font-style: normal;font-display: swap;}
@font-face {font-family: 'Montserrat';src: url('https://res.cloudinary.com/arhamsoft-ltd/raw/upload/v1658742665/hex-nft/assets/Fonts/Montserrat-Bold_gxiopr.woff2') format('woff2'),    url('https://res.cloudinary.com/arhamsoft-ltd/raw/upload/v1658742665/hex-nft/assets/Fonts/Montserrat-Bold_wh7wz2.woff') format('woff');font-weight: bold;font-style: normal;font-display: swap;}
/* Reset Styles */
body{min-width:280px;font-family: 'Neon';background: #1A1A1A;}
.breadcrumb-area{margin-top: 155px;/* box-shadow: 0 0 5px #2cfafe; */}
.dropdown-item{    font: normal normal normal 14px/18px Montserrat;display: inline-block;line-height: 20px;}
a{text-decoration: none;color:#D81796;}
.ff-Neon{font-family: 'Neon';}
.ff-monst{font-family: 'Montserrat' ;}
h2 {font-size: 42px;line-height: 58px;font-weight: 900;}
/* Custom Classses */
#root{overflow: hidden;}
.cursor-pointer{cursor:pointer;}
.not-allowed{cursor:not-allowed;}
.transition{transition:all ease-in-out 0.3s;}
.btn-gradiant-bg{border-radius:20px;font-size:16px;line-height:20px;overflow: hidden;background: rgb(13,224,232);background: linear-gradient(0deg, rgba(13,224,232,1) 0%, rgba(203,3,251,1) 100%);padding:1px;}
.btn-gradiant-bg span.outer{display:block;height:100%;background:#000206;padding:15px 60px;border-radius:20px;overflow: hidden;}
.btn-gradiant-bg span.inner{color:#fff;}
.btn-gradiant-bg:hover span.inner{background-color: red;background-image: linear-gradient(45deg, #f3ec78, #af4261);background-size: 100%;background-repeat: repeat;-o-background-clip: text;-ms-background-clip: text;-moz-background-clip: text;-webkit-background-clip: text;background-clip: text;-moz-text-fill-color: transparent;-webkit-text-fill-color: transparent;}
.btn-filled{font-size:20px;line-height:24px;border-radius: 6px;overflow: hidden;background: #2cfafe;padding:1px;font-family: 'Montserrat';font-weight: bold;}
.btn-outlined{font-size:20px;line-height:24px;border-radius: 6px;overflow: hidden;background: #fff;padding:1px;font-family: 'Montserrat';font-weight: bold;}
.btn-filled span,.btn-outlined span{border-radius: 6px;overflow: hidden;padding: 11px 24px;color: black;}
.btn-filled:hover span,.btn-outlined:hover span{background: #1554F0;color: #fff;}
.btn-filled:hover, .btn-outlined:hover{background: #1554F0; color: #fff ;}
.btn-white-outline,.btn-white-outline:focus{border:1px solid #fff;border-radius:25px;padding:7px 35px;background:transparent;color:#fff;}
.slick-slider{width:auto ; min-width: 100% ;}
select,.custom-file-input label{font-size: 18px ;line-height: 30px ;font-weight: 500 ;color: #969CA2 ;font-family: 'Montserrat' ;}
.form-control,.custom-file span, .author-area .item-form .form-group input ,.author-area  textarea{font-size: 18px;line-height: 30px;font-weight: 500;color: #1a1a1a ; font-family: 'Montserrat';background-color: #fff;}
.form-control::placeholder{color: #969CA2 ;}
label{font-size: 20px;line-height: 30px;font-weight: 500;color: #969CA2;font-family: 'Montserrat';}
.profile-detail-banner button,#create-collection button,#contact-form button,#create-collection button{border: 1px solid #2cfafe;color: #1a1a1a ;background-color: #2cfafe; border-radius: 10px;padding: 11px 24px;font-weight: bold;font-size: 20px;transition: all ease-in-out 0.3s;font-family: 'Montserrat';}
.profile-detail-banner h5{color: rgb(127, 136, 144);font-family: 'Montserrat';    word-break: break-word; max-width: 100%;}
.author-area .card .card-body .input-group .form-control{color: rgb(127, 136, 144);font-family: 'Montserrat';padding-right: 50px; border-radius: 10px;}
/* .author-area .card .card-caption .input-group .form-control{} */
.input-group-text{font-family: 'Montserrat';font-size: 14px;}
.card-caption p{color:rgb(127, 136, 144);font-family: 'Montserrat';}
.profile-img-in p{color:rgb(127, 136, 144);font-family: 'Montserrat'; word-break: break-word; max-width: 100%}
.outline-button{border: 2px solid #2cfafe;background: #333633 0% 0% no-repeat padding-box;font-size: 20px;line-height: 24px;border-radius: 6px;color: #2cfafe;padding: 11px 24px;font-family: 'Montserrat';font-weight: bold;transition: all ease-in-out 0.3s;}
.outline-button:hover{background-color: #2cfafe;color: #1a1a1a ;border: 2px solid #2cfafe ;}
.profile-detail-banner button:hover span,#create-collection	button:hover span,#contact-form button:hover span,#create-collection button:hover span{color: white;}
.profile-detail-banner button:hover,#create-collection	button:hover ,#contact-form button:hover,#create-collection button:hover{  border: 1px solid #1554F0;color: rgb(127, 136, 144) ;background: #1554F0 0% 0% no-repeat padding-box;border-radius: 6px;color: white;}
/**/
select.form-control {color: #7f8890 ;}
option:not(:first-of-type) {color: #1a1a1a ;}
select.form-control:focus{color: #1a1a1a ;}
/*react tool tip*/
.__react_component_tooltip.type-dark{background-color: #2cfafe;color: #1a1a1a;font-weight: bold;font-family: 'Montserrat';}
.__react_component_tooltip.type-dark.place-bottom:after {border-bottom-color: #2cfafe; }
.__react_component_tooltip.type-dark.place-top:after {border-top-color: #2cfafe; }
.__react_component_tooltip.type-dark.place-right:after {border-right-color: #2cfafe; }
.__react_component_tooltip.type-dark.place-left:after {border-left-color: #2cfafe; }
/**/
.widget-content.search-widget input{color: #1a1a1a ;}
/*responsive*/
@media only screen and (max-width:1799px){
	h2 {font-size: 35px;}
}
@media only screen and (max-width:1599px){
	h2 {font-size: 32px;}
}
@media only screen and (max-width:1199px){
	.btn-gradiant-bg span.outer{padding:10px 20px;}
	.btn-filled {font-size: 16px;}
	.btn-outlined{font-size:16px;}
	h2 {font-size: 26px;}
}
@media only screen and (max-width:991px){
	/* .btn-filled span, .btn-outlined span{padding:7px 20px 7px;} */
	.btn-filled span, .btn-outlined span {padding: 14px 30px;}
	.btn-filled {font-size: 14px;}
	.btn-outlined{font-size:14px;}
	h2 {font-size: 20px;}
	select, .custom-file-input label {font-size: 16px ;}
}
@media only screen and (max-width:767px){

}
@media only screen and (max-width:575px){
	.form-group .form-control::placeholder, .form-control, .custom-file span, .author-area .item-form .form-group input, .author-area textarea {font-size: 15px;line-height: 20px;}
	.profile-detail-banner button, #create-collection button, #contact-form button, #create-collection button {font-size: 15px;}
	.outline-button {font-size: 15px;}
}
@media only screen and (max-width:479px){
	.btn-filled span, .btn-outlined span {padding: 10px 20px;}
	h2 {font-size: 16px;line-height: 26px;}
	.author-area .item-form .form-group input, .author-area .item-form .form-group textarea, .author-area .item-form .form-group select, .author-area .item-form .form-group .custom-file-input, .author-area .item-form .form-group .custom-file-label, .author-area .item-form .form-group .custom-file-label::after {font-size: 13px ;}
	.input-group-text {font-size: 12px;}
	.form-group .form-control::placeholder, .form-control, .custom-file span, .author-area .item-form .form-group input, .author-area textarea {font-size: 13px;line-height: 17px;}
}
@media only screen and (max-width:319px){

}